import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { StoreModule } from '@ngrx/store';
import { videoReducer, trackReducer, podcastReducer } from './shared/store/store.reducer';
import { AuthInterceptorService } from './shared/SharedServices/auth-interceptor.service';
import { AuthGuard } from './auth/services/auth.guard';

import { MatSnackBarModule } from '@angular/material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
registerLocaleData(localeFr, 'fr');
import { NgxMaskModule, IConfig } from 'ngx-mask';
 
const maskConfig: Partial<IConfig> = {
  validation: false,
};
const appRoutes: Routes = [
  // Athentifications routes will be loaded in the root path
  {
    
    path: "login",
    loadChildren: () =>
      import("./auth/login/login.module").then(m => m.LoginModule),
      canActivate : [AuthGuard],
  },
  {
    path: "password",
    loadChildren: () =>
      import("./auth/password/password.module").then(m => m.PasswordModule),
      canActivate : [AuthGuard]
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./main/admin-management/admin-management.module").then(m => m.AdminManagementModule),
      canActivate : [AuthGuard]
  },
  {
    path: "album",
    loadChildren: () =>
      import("./main/album/album.module").then(m => m.AlbumModule),
      canActivate : [AuthGuard]
  },
  {
    path: "stats",
    loadChildren: () =>
      import("./main/analytic/analytic.module").then(m => m.AnalyticModule),
      canActivate : [AuthGuard]
  },
  {
    path: "track",
    loadChildren: () =>
      import("./main/track/track.module").then(m => m.TrackModule),
      canActivate : [AuthGuard]
  },
  {
    path: "video",
    loadChildren: () =>
      import("./main/video/video.module").then(m => m.VideoModule),
      canActivate : [AuthGuard]
  },
   {
    path: "news",
    loadChildren: () =>
      import("./main/news/news.module").then(m => m.NewsModule),
      canActivate : [AuthGuard]
  },
    {
    path: "users",
    loadChildren: () =>
      import("./main/users/users.module").then(m => m.UsersModule),
      canActivate : [AuthGuard]
  },
  {
    path: "shows",
    loadChildren: () =>
      import("./main/show/show.module").then(m => m.ShowModule),
      canActivate : [AuthGuard]
  },
  {
    path: "podcasts",
    loadChildren: () =>
      import("./main/podcasts/podcasts.module").then(m => m.PodcastsModule),
      canActivate : [AuthGuard]
  },
  {
    path: "slides",
    loadChildren: () =>
      import("./main/slides/slides.module").then(m => m.SlidesModule),
      canActivate : [AuthGuard]
  },
  {
    path: "playlists",
    loadChildren: () =>
      import("./main/playlist/playlist.module").then(m => m.PlaylistModule),
      canActivate : [AuthGuard]
  },
  {
    path: "credits",
    loadChildren: () =>
      import("./main/credit/credit.module").then(m => m.CreditModule),
      canActivate : [AuthGuard]
  },
  {
    path: "explorer",
    loadChildren: () =>
      import("./shared/file-manager/file-manager.module").then(
        m => m.FileManagerModule
      ),canActivate : [AuthGuard]
  },
  {
    path: "content",
    loadChildren: () =>
      import("./main/content/content.module").then(m => m.ContentModule),
      canActivate : [AuthGuard]
  },
  {
    path: "archive",
    loadChildren: () =>
      import("./main/archive/archive.module").then(m => m.ArchiveModule),
      canActivate : [AuthGuard]
  },
  {
    path: "",
    redirectTo: "album",
    pathMatch: "full"
  },
  {
    path: "**",
    loadChildren: () =>
      import("./shared/404/error-404.module").then(m => m.Error404Module),
      canActivate : [AuthGuard]
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot() ,
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    // Material moment date module
    MatMomentDateModule,
    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        //Store
        // ngrx
        StoreModule.forRoot({ video: videoReducer, track: trackReducer, podcast:podcastReducer })
    ],
    bootstrap: [
        AppComponent
    ],
    providers : [  {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true
      }]
})
export class AppModule {}
