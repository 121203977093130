import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";
import { MockService } from "app/shared/SharedServices/mock.service";
import { ConfirmDialogComponent } from 'app/shared/confirmation-dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  notifications: any[];
  unseenNotifications: any[];
  loaded : any = {notifications : false}
  // Private
  private _unsubscribeAll: Subject<any>;
  username: string;
  firstWord: any;
  email: string;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _mockService: MockService,
    public dialog: MatDialog,
    private _router: Router
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: "Online",
        icon: "icon-checkbox-marked-circle",
        color: "#4CAF50"
      },
      {
        title: "Away",
        icon: "icon-clock",
        color: "#FFC107"
      },
      {
        title: "Do not Disturb",
        icon: "icon-minus-circle",
        color: "#F44336"
      },
      {
        title: "Invisible",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#BDBDBD"
      },
      {
        title: "Offline",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#616161"
      }
    ];

    this.languages = [
      {
        id: "fr",
        title: "Français",
        flag: "fr"
      },
      {
        id: "en",
        title: "English",
        flag: "us"
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this.username= localStorage.getItem("username") || "Admin Mobelite";
    this.firstWord= this.username.charAt(0).toUpperCase();
    this.email= localStorage.getItem("email") || "admin@mobelite.fr";
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(settings => {
        this.horizontalNavbar = settings.layout.navbar.position === "top";
        this.rightNavbar = settings.layout.navbar.position === "right";
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang
    });
    //load notifications
    this.loadNotifications();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  loadNotifications() {
    this._mockService.loadNotifications().subscribe(
      (resp: any) => {
        this.notifications = resp.data;
        this.unseenNotifications = this.notifications.filter(e =>(!e.seen));
        this.loaded.notifications = true ;
      },
      err => {
        console.error(err);
      }
    );
  }
  openDialog($event): void {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      height: "200px",
      //disableClose : true,
      data: {
        type: "confirmation",
        message: "Êtes-vous sûr de vouloir vous déconnecter ? "
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        {
          localStorage.removeItem('token')
          localStorage.removeItem('username')
          localStorage.removeItem('email')

          this._router.navigate(['/login'])
        }
      }
    });
  }
}
