import { createAction, props } from '@ngrx/store';
import { Video, Track, Podcast } from './store.state';
/**
 * Video store actions
 */
export const saveVideoState = createAction('[Video] New',props<Video>());
export const initVideoState = createAction('[Video] Empty');

/**
 * Track store actions
 */
export const saveTrackState = createAction('[Track] New',props<Track>())
export const initTrackState = createAction('[Track] Empty')

/**
 * Podcast store action
 */
export const savePodcastState = createAction('[Podcast] New',props<Podcast>())
export const initPodcastState = createAction('[Podcast] Empty')