<!-- Confirmation Dialog -->
<div *ngIf="data.type=='confirmation'">
  <div mat-dialog-title>{{data.message}}</div>
  <div mat-dialog-content>

  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Non</button>
    <button mat-button [mat-dialog-close]="'Yes'" cdkFocusInitial>OUI</button>
  </div>
</div>

<!-- Information Dialog -->
<div *ngIf="data.type=='info'">
  <div mat-dialog-title>{{data.message}}</div>
  <div mat-dialog-content>

  </div>
  <div mat-dialog-actions>
    <button style="margin: auto;" mat-button [mat-dialog-close]="'Yes'" cdkFocusInitial>Ok</button>
  </div>
</div>