
<div id="main">

    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above -->

    <div id="container-1" class="container">

        <!-- NAVBAR: Left - activated sidebar notnav bar -->
        <ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Left -->

        <div id="container-2" class="container">

            <!-- TOOLBAR: Below fixed -->
            <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-fixed'">
                <ng-container *ngTemplateOutlet="toolbar"></ng-container>
            </ng-container>
            <!-- / TOOLBAR: Below fixed -->

            <div id="container-3" class="container" fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

                <!-- TOOLBAR: Below static -->
                <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-static'">
                    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
                </ng-container>
                <!-- / TOOLBAR: Below static -->

                <!-- CONTENT -->
                <content></content>
                <!-- / CONTENT -->

                <!-- FOOTER: Below static -->
                <ng-container *ngIf="fuseConfig.layout.footer.position === 'below-static'">
                    <ng-container *ngTemplateOutlet="footer"></ng-container>
                </ng-container>
                <!-- / FOOTER: Below static -->

            </div>

            <!-- FOOTER: Below fixed -->
            <ng-container *ngIf="false">
                <ng-container *ngTemplateOutlet="footer"></ng-container>
            </ng-container>
            <!-- / FOOTER: Below fixed -->

        </div>

        <!-- NAVBAR: Right -->
        <ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
            <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Right -->

    </div>

    <!-- FOOTER: Above -->
    <ng-container *ngIf="fuseConfig.layout.footer.position === 'above'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above -->

</div>
<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden"
        [ngClass]="fuseConfig.layout.toolbar.customBackgroundColor === true ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background : fuseConfig.layout.toolbar.position">
    </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- LEFT SIDEBAR -->
<ng-template #leftNavbar>
    <fuse-sidebar name="navbar" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded"
        lockedOpen="gt-md" *ngIf="!fuseConfig.layout.navbar.hidden">
        <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / LEFT SIDEBAR -->