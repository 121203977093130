import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'analytic',
        title: 'Tableau de bord',
        icon: 'poll',
        type: 'collapsable',
        children: [
            {
                id: 'users-stats',
                title: 'Utilisateurs',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/stats/users'

            },
            {
                id: 'album-stats',
                title: 'Musique',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/stats/music'

            } ,
            {
                id: 'video-stats',
                title: 'Vidéo',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/stats/video'

            } 
        ]
    },
    {
        id: 'music',
        title: 'Musique',
        translate: 'NAV.MUSIC',
        icon: 'music_note',
        type: 'collapsable',
        children: [
            {
                id: 'albums',
                title: 'Albums',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/album/all'

            },
            {
                id: 'purchase',
                title: 'Album Purchase',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/album/purchase'

            },
            {
                id: 'create_album',
                title: 'Créer un album',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/album/new'

            },
            {
                id: 'create_track',
                title: 'Ajouter un titre',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/track/new'

            },
            {
                id: 'create_playlist',
                title: 'Créer une playlist',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/playlists/new'

            },
            {
                id: 'playlist',
                title: 'Playlists',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/playlists/all'

            }
        ]
    },
    {
        id: 'credit',
        title: 'Crédits',
        //translate: 'NAV.User',
        icon: 'copyright',
        type: 'item',
        url: "/credits"

    },
    {
        id: 'video',
        title: 'Video',
        translate: 'NAV.VIDEO',
        icon: 'music_video',
        type: 'collapsable',
        children: [
            {
                id: 'videos',
                title: 'Vidéos',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/video/categories'

            },
            {
                id: 'create_video',
                title: 'Créer une vidéo',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/video/new'

            },
            {
                id: 'list_Playlist_video',
                title: 'Playlist vidéo',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/video/playlist'

            },
            {
                id: 'create_playlist_video',
                title: 'Créer une playlist vidéo',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/video/newplaylist'

            },
          
        ]
    },{
        id: 'Kiosque',
        title: 'Kiosque',
        icon: 'library_music',
        type: 'collapsable',
        children: [
             {
                id: 'news',
                title: 'Actualités',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/news/all'

            }, 
            {
                id: 'create_actu',
                title: 'Créer une actualité',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/news/new'

            },
            {
                id: 'content',
                title: 'Contenus',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/content/all'

            }, 
            {
                id: 'create_content',
                title: 'Créer un contenu',
                //translate: 'NAV.album.create',
                type: 'item',
                url: '/content/new'

            }
        ]
    },
    {
        id: 'video',
        title: 'Utilisateurs',
        //translate: 'NAV.User',
        icon: 'person',
        type: 'item',
        url: "/users"

    },
    {
        id: 'show',
        title: 'Émissions',
        //translate: 'NAV.User',
        icon: 'tv',

        type: "collapsable",
        children: [
            {
                id: 'shows',
                title: 'Émissions',
                type: 'item',
                url: "/shows/all",

            },
            {
                id: 'new-show',
                title: 'Créer une émission',
                type: 'item',
                url: "/shows/new",

            },
            {
                id: 'new-podcast',
                title: 'Ajouter un podcast',
                type: 'item',
                url: "/podcasts/new"

            }],
    },
    {
        id: 'home-page',
        title: 'Accueil de l\'application',
        //translate: 'NAV.User',
        icon: 'home',

        type: "collapsable",
        children: [
            {
                id: 'slide',
                title: 'Gestion des slides',
                type: 'item',
                url: "/slides/all",

            }],
    },
    {
        id: 'config',
        title: 'Configuration',
        //translate: 'NAV.User',
        icon: 'settings',

        type: "collapsable",
        children: [
            {
                id: 'demand',
                title: 'Gestion des demands',
                type: 'item',
                url: "/admin/demand",

            },
            {
                id: 'subscription',
                title: 'Gestion des abonnements',
                type: 'item',
                url: "/admin/subscription",

            },
            {
                id: 'admin-management',
                title: 'Gestion des admins',
                type: 'item',
                url: "/admin/all",

            },
            {
                id: 'new-admin',
                title: 'Créer un administrateur',
                type: 'item',
                url: "/admin/new",

            }
        ],
    },
    {
        id: 'archive-page',
        title: 'Archivage',
        //translate: 'NAV.User',
        icon: 'archive',

        type: "collapsable",
        children: [
            {
                id: 'album',
                title: 'Albums',
                type: 'item',
                url: "/archive/albums",

            },
            {
                id: 'titre',
                title: 'Titres',
                type: 'item',
                url: "/archive/titres",

            },
            {
                id: 'video',
                title: 'videos',
                type: 'item',
                url: "/archive/videos",

            }
           ],
    },
];
