export const locale = {
    lang: 'fr',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'MUSIC' : 'Musique',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'album' : {
                'create' : "Créer un album"
            }

        }
    }
};
