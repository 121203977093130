import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {  MatDialogModule, MatButtonModule } from '@angular/material';
import { Routes, RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';


const routes : Routes = [{path:'dialog',component : ConfirmDialogComponent}]
@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    FuseSharedModule,
    MatDialogModule
  ]
})
export class DialogModule { }
