import { createReducer, on } from '@ngrx/store';
import { saveVideoState, initVideoState, saveTrackState, initTrackState, savePodcastState, initPodcastState } from './store.action';
import { getEmptyVideo, getEmptyTrack, getEmptyPodcast } from './store.state';
/**
 * Initial states
 */
export const videoInitialState = getEmptyVideo()
export const trackInitialState = getEmptyTrack()
export const podcastInitialState = getEmptyPodcast()
/**
 * Creating reducers
 */
const _videoReducer = createReducer(videoInitialState,
  on(saveVideoState, (state, videoData) => Object.assign(state,videoData)),
  on(initVideoState, (state) => {; return getEmptyVideo()}),
);
const _trackReducer = createReducer(trackInitialState,
  on(saveTrackState, (state, trackData) => Object.assign(state,trackData)),
  on(initTrackState, (state) => {; return getEmptyTrack()}),
);
const _podcastReducer = createReducer(podcastInitialState,
  on(savePodcastState, (state, podcastData) => Object.assign(state,podcastData)),
  on(initPodcastState, (state) => {; return getEmptyPodcast()}),
);

/**
 * Create a reducer to be injected in StoreModule
 * @param state current video state
 * @param action action dispatched
 */
export function videoReducer(state, action) {
  
  return _videoReducer(state, action);
}
/**
 * Create a reducer to be injected in StoreModule
 * @param state current Track state
 * @param action track action to be dispatched
 */
export function trackReducer(state, action) {
  
  return _trackReducer(state, action);
}
/**
 * Create a reducer to be injected in StoreModule
 * @param state current Podcast state
 * @param action podcast action to be dispatched
 */
export function podcastReducer(state, action) {
  
  return _podcastReducer(state, action);
}

