import { Routes } from "@angular/router";
import "hammerjs";
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AuthGuard } from './auth/services/auth.guard';
registerLocaleData(localeFr, 'fr');
import { IConfig } from 'ngx-mask';
var maskConfig = {
    validation: false,
};
var ɵ0 = function () {
    return import("./auth/login/login.module.ngfactory").then(function (m) { return m.LoginModuleNgFactory; });
}, ɵ1 = function () {
    return import("./auth/password/password.module.ngfactory").then(function (m) { return m.PasswordModuleNgFactory; });
}, ɵ2 = function () {
    return import("./main/admin-management/admin-management.module.ngfactory").then(function (m) { return m.AdminManagementModuleNgFactory; });
}, ɵ3 = function () {
    return import("./main/album/album.module.ngfactory").then(function (m) { return m.AlbumModuleNgFactory; });
}, ɵ4 = function () {
    return import("./main/analytic/analytic.module.ngfactory").then(function (m) { return m.AnalyticModuleNgFactory; });
}, ɵ5 = function () {
    return import("./main/track/track.module.ngfactory").then(function (m) { return m.TrackModuleNgFactory; });
}, ɵ6 = function () {
    return import("./main/video/video.module.ngfactory").then(function (m) { return m.VideoModuleNgFactory; });
}, ɵ7 = function () {
    return import("./main/news/news.module.ngfactory").then(function (m) { return m.NewsModuleNgFactory; });
}, ɵ8 = function () {
    return import("./main/users/users.module.ngfactory").then(function (m) { return m.UsersModuleNgFactory; });
}, ɵ9 = function () {
    return import("./main/show/show.module.ngfactory").then(function (m) { return m.ShowModuleNgFactory; });
}, ɵ10 = function () {
    return import("./main/podcasts/podcasts.module.ngfactory").then(function (m) { return m.PodcastsModuleNgFactory; });
}, ɵ11 = function () {
    return import("./main/slides/slides.module.ngfactory").then(function (m) { return m.SlidesModuleNgFactory; });
}, ɵ12 = function () {
    return import("./main/playlist/playlist.module.ngfactory").then(function (m) { return m.PlaylistModuleNgFactory; });
}, ɵ13 = function () {
    return import("./main/credit/credit.module.ngfactory").then(function (m) { return m.CreditModuleNgFactory; });
}, ɵ14 = function () {
    return import("./shared/file-manager/file-manager.module.ngfactory").then(function (m) { return m.FileManagerModuleNgFactory; });
}, ɵ15 = function () {
    return import("./main/content/content.module.ngfactory").then(function (m) { return m.ContentModuleNgFactory; });
}, ɵ16 = function () {
    return import("./main/archive/archive.module.ngfactory").then(function (m) { return m.ArchiveModuleNgFactory; });
}, ɵ17 = function () {
    return import("./shared/404/error-404.module.ngfactory").then(function (m) { return m.Error404ModuleNgFactory; });
};
var appRoutes = [
    // Athentifications routes will be loaded in the root path
    {
        path: "login",
        loadChildren: ɵ0,
        canActivate: [AuthGuard],
    },
    {
        path: "password",
        loadChildren: ɵ1,
        canActivate: [AuthGuard]
    },
    {
        path: "admin",
        loadChildren: ɵ2,
        canActivate: [AuthGuard]
    },
    {
        path: "album",
        loadChildren: ɵ3,
        canActivate: [AuthGuard]
    },
    {
        path: "stats",
        loadChildren: ɵ4,
        canActivate: [AuthGuard]
    },
    {
        path: "track",
        loadChildren: ɵ5,
        canActivate: [AuthGuard]
    },
    {
        path: "video",
        loadChildren: ɵ6,
        canActivate: [AuthGuard]
    },
    {
        path: "news",
        loadChildren: ɵ7,
        canActivate: [AuthGuard]
    },
    {
        path: "users",
        loadChildren: ɵ8,
        canActivate: [AuthGuard]
    },
    {
        path: "shows",
        loadChildren: ɵ9,
        canActivate: [AuthGuard]
    },
    {
        path: "podcasts",
        loadChildren: ɵ10,
        canActivate: [AuthGuard]
    },
    {
        path: "slides",
        loadChildren: ɵ11,
        canActivate: [AuthGuard]
    },
    {
        path: "playlists",
        loadChildren: ɵ12,
        canActivate: [AuthGuard]
    },
    {
        path: "credits",
        loadChildren: ɵ13,
        canActivate: [AuthGuard]
    },
    {
        path: "explorer",
        loadChildren: ɵ14, canActivate: [AuthGuard]
    },
    {
        path: "content",
        loadChildren: ɵ15,
        canActivate: [AuthGuard]
    },
    {
        path: "archive",
        loadChildren: ɵ16,
        canActivate: [AuthGuard]
    },
    {
        path: "",
        redirectTo: "album",
        pathMatch: "full"
    },
    {
        path: "**",
        loadChildren: ɵ17,
        canActivate: [AuthGuard]
    }
];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17 };
