import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router) {
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var isPublic = (state.url.includes("login")) || (state.url.includes("password"));
        var token = localStorage.getItem('token');
        if (isPublic) {
            if (token) {
                this.router.navigate(['/']);
                return false;
            }
            else
                return true;
        }
        else {
            if (token)
                return true;
            else {
                this.router.navigate(['/login']);
                return false;
            }
        }
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
