export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'VIDEO': 'Video',
            
            'SAMPLE': {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'album': {
                'create': "Créer un album"
            }

        }
    }
};
