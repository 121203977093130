import { environment } from "environments/environment";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MockService = /** @class */ (function () {
    function MockService(httpClient) {
        var _this = this;
        this.httpClient = httpClient;
        this.config = {};
        this.httpClient.get(environment.apiBaseUrl + "/mock/config").subscribe(function (res) {
            _this.config = res;
        }, function (err) {
            console.error(err);
        });
    }
    MockService.prototype.loadNotifications = function () {
        return this.httpClient
            .get(environment.apiBaseUrl + "/notification")
            .pipe(map(function (response) { return response; }));
    };
    MockService.prototype.checkNotification = function (id) {
        return this.httpClient
            .patch(environment.apiBaseUrl + "/notification/" + id + "/checked", {})
            .pipe(map(function (response) { return response; }));
    };
    MockService.prototype.setConfig = function (config) {
        this.config = config;
    };
    MockService.prototype.getConfig = function () {
        return this.config;
    };
    MockService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MockService_Factory() { return new MockService(i0.ɵɵinject(i1.HttpClient)); }, token: MockService, providedIn: "root" });
    return MockService;
}());
export { MockService };
