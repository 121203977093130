import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('token')
    request = request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
        'client': "admin"
      }
    });

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // http response status code
          if (event.body.status == "401")
            this.router.navigate(['/login'])
        }
      }, error => {
        
        console.log("Response intercepted with error", error.message)
        if (error.status == 401)
          {
            localStorage.removeItem('token')
            this.router.navigate(['/login'])}
      })
    );
  }
}