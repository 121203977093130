/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./layout/vertical/layout-1/layout-1.component.ngfactory";
import * as i3 from "./layout/vertical/layout-1/layout-1.component";
import * as i4 from "../@fuse/services/config.service";
import * as i5 from "./app.component";
import * as i6 from "@angular/common";
import * as i7 from "../@fuse/components/navigation/navigation.service";
import * as i8 from "../@fuse/components/sidebar/sidebar.service";
import * as i9 from "../@fuse/services/splash-screen.service";
import * as i10 from "../@fuse/services/translation-loader.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "./shared/SharedServices/previous-route.service";
import * as i14 from "./shared/SharedServices/mock.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-1", [], null, null, null, i2.View_VerticalLayout1Component_0, i2.RenderType_VerticalLayout1Component)), i1.ɵdid(2, 245760, null, 0, i3.VerticalLayout1Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i5.AppComponent, [i6.DOCUMENT, i4.FuseConfigService, i7.FuseNavigationService, i8.FuseSidebarService, i9.FuseSplashScreenService, i10.FuseTranslationLoaderService, i11.TranslateService, i12.Platform, i13.PreviousRouteService, i14.MockService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
