import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    let isPublic = (state.url.includes("login")) || (state.url.includes("password"))
    let token = localStorage.getItem('token')
    
    if (isPublic) {
      if (token) { this.router.navigate(['/']); return false }
      else return true
    }
    else {
      if (token)
        return true
      else { this.router.navigate(['/login']); return false; }
    }
  }

}
