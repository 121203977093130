/**
 * Video state interface
 */
export interface Video {
    title: String,
    titleNewness: String,
    description: String,
    cover: String,
    coverNewness: String,
    secondaryNewnessTitle: String,
    category: String,
    subCategory: String,
    sourceVideo: {
        path: String,
        name: String
    },
    sourceVideoTeaser:{
        path: String,
        name: String
    }
}
export function getEmptyVideo(): Video {
    let result: Video = {
        title: "",
        titleNewness: "",
        description: "",
        cover: "",
        category: null,
        subCategory: null,
        sourceVideo: null,
        sourceVideoTeaser:null,
        coverNewness: "",
        secondaryNewnessTitle: "",
    }
    return result;
}
/**
 * Track state interface
 */
export interface Track {
    title: String,
    artists: String[],
    artistsFeaturing: String[],
    album: String,
    sourceTrack: {
        path: String,
        name: String
    }
}
export function getEmptyTrack(): Track {
    let result: Track = {
        title: "",
        artists: [],
        artistsFeaturing: [],
        album: "",
        sourceTrack: null
    }
    return result;
}
/**
 * Podcast state interface
 */
export interface Podcast {
    title: String,
    description: String,
    show: String,
    sourcePodcast: {
        path: String,
        name: String
    }
}
export function getEmptyPodcast(): Podcast {
    let result: Podcast = {
        title: "",
        description: "",
        show:"",
        sourcePodcast: null
    }
    return result;
}