import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class MockService {
  constructor(private httpClient: HttpClient) {
    this.httpClient.get(`${environment.apiBaseUrl}/mock/config`).subscribe(
      res => {
        this.config = res;
      },
      err => {
        console.error(err);
      }
    );
  }

  public loadNotifications() {
    return this.httpClient
      .get(`${environment.apiBaseUrl}/notification`)
      .pipe(map(response => response));
  }
  public checkNotification(id) {
    return this.httpClient
      .patch(`${environment.apiBaseUrl}/notification/${id}/checked`, {})
      .pipe(map(response => response));
  }
  private config: any = {};

  public setConfig(config: any) {
    this.config = config;
  }

  public getConfig(): any {
    return this.config;
  }
}
